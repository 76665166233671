import GridComponent from '../components/Grid/viewer/Grid';
import GridController from '../components/Grid/viewer/Grid.controller';


const Grid = {
  component: GridComponent,
  controller: GridController
};


export const components = {
  ['Grid']: Grid
};

